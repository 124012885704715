import * as React from 'react'
import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import NavHeader from '../components/products/NavHeader'
import ImageViewer from '../components/products/zkittlez/ImageViewer'
import ContextConsumer from '../components/Context'

const Zkittlez = ({pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <ContextConsumer>
      {({ data, set }) => (
    <Page>
      <Container>
<NavHeader productName={'zkittlez'}/>
<ImageViewer items={data.cartItems} productName={'ZKITTLEZ'} />
      </Container>
    </Page>
      )}
    </ContextConsumer>
  </IndexLayout>
)

export default Zkittlez
